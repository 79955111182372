import React, { useEffect } from 'react'
import "./footer.css";
import LangSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';  
import Aos from 'aos';
import "aos/dist/aos.css";

const Footer = () => {
  const { t } = useTranslation()

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div data-aos="fade" className="footer">
      <div data-aos="fade-up" className="text">{t('COMING-SOON')}</div>
      <div  data-aos="fade-up" className="svg_logo2">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151.58 124.06">
                <g id="Layer_2" data-name="Layer 2"><polygon className="cls-2" points="0.5 20.52 20.52 0.5 0.5 0.5 0.5 20.52"/></g>
                <g id="Layer_3" data-name="Layer 3"><polygon className="cls-2" points="20.52 20.52 0.5 40.61 0.71 122.19 114.39 1 85.03 1 20.52 69.25 20.52 20.52"/></g>
                <g id="Layer_4" data-name="Layer 4"><polygon className="cls-2" points="81.97 55.13 63.54 73.56 113.54 123.56 150.37 123.56 81.97 55.13"/></g>
                </svg></div>
      <div data-aos="fade-up" className="text_2">- {t('PROJECT')}</div>
      <div data-aos="fade-up" className="text_3">- {t('HOME')}</div>
      <div data-aos="fade-up" className="p_t">{t('PRIVACY-POLICY')}   |   {t('TERMS-CONDITIONS')}</div>
      <div data-aos="fade-up" className="page_terms">このホームページに記載されている一切の文書・写真・イラスト等を、
  手段や形態を問わず複写・転載することを禁じます。</div>
      <div data-aos="fade-up" className="c_com"> © 2022 {t('PROJECT')} KIKARU</div>
    </div>
  );
};

export default Footer;