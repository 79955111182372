import React from "react";
import { useTranslation } from 'react-i18next';
// import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import '../defaults.css'
import './login.css'
import firebaseApp from "./utils/firebase"
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, updateCurrentUser, updateProfile } from 'firebase/auth'
import Modal from 'react-modal'
import { useHistory } from "react-router-dom";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 

Modal.setAppElement("#root")

const Login = () => {

    const auth = getAuth(firebaseApp)

    const passwordPattern = /^.{8,256}$/gi;

    const usernamePattern = /^(?=.{3,24}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/

    const [modalIsOpen, setIsOpen] = React.useState(false);

    const [email, setEmail] = React.useState('')

    const [password, setPassword] = React.useState('')

    const [confirmPassword, setConfirmPassword] = React.useState('')

    const [mode, setMode] = React.useState(true)

    const [passwordClass, setPasswordClass] = React.useState('') 

    const [passwordConfirmClass, setPasswordConfirmClass] = React.useState('') 

    const [error, setError] = React.useState('')

    const [signupPage, setSignupPage] = React.useState(0)

    const history = useHistory()

    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }
    const { t } = useTranslation() 

    function signIn() {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user
                // history.push("/home")
            })
            .catch((error) => {
                console.log(error)
                toast('Error logging in.', {
                    position: "bottom-center",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    type: "error",
                    });
            })
    }

    function signUp() {
        createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                const user = userCredential.user
                // history.push("/home")
                history.push("/")
                updateProfile(auth.currentUser, {
                displayName:username
                })   
            })
            .catch((error) => {
                console.log(error)
                toast('Error signing up.', {
                    position: "bottom-center",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    type: "error",
                    });
            })
    }




    function setLogin() {
        setMode(true)
    }

    function setSignup() {
        setMode(false)
    }

    const [username, setUsername]  = React.useState('');

    const [usernameClass, setUsernameClass] = React.useState('');

    const [nextButtonClass, setNextButtonClass] = React.useState('greyedOutField');

    function updateUsername(event) {

        setUsername(event.target.value)

        if(event.target.value == "") {setUsernameClass(""); return}

        if(!usernamePattern.test(event.target.value)) {
            //if username does not match regex (bad)
            setUsernameClass("badField")
        } else {
            setUsernameClass("goodField")
        }


    }


    function updatePassword(event) {

        setPassword(event.target.value)

        if(password !== confirmPassword) {
            if(confirmPassword !== "") setPasswordConfirmClass('badField')
        }

        if(event.target.value == "") {setPasswordClass(""); return}

        if(!passwordPattern.test(event.target.value)) {
             //if it's a bad password, do this
             setPasswordClass("badField")

        } else {

             setPasswordClass("goodField")

        }


    }

    function updateConfirmPassword(event) {

        setConfirmPassword(event.target.value)

        if(event.target.value == "") {
            setPasswordConfirmClass("") 
        } else {
            if(event.target.value !== password) {
                //if password is bad
                setPasswordConfirmClass("badField")

            } else {
                //if good
                setPasswordConfirmClass("goodField")

            }
        }

   }


   const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

   const [emailClass, setEmailClass] = React.useState('');

    function updateEmail(event) {

        setEmail(event.target.value)

        if(!event.target.value) {
            setEmailClass("")
            return;
        }

        if(!emailPattern.test(event.target.value)) {
             //if it's a bad password, do this
             setEmailClass("badField")
        } else {
             setEmailClass("goodField")
        }

        
    }

    const stupidConditional = emailClass == "goodField" && password === confirmPassword && passwordClass == "goodField" && passwordConfirmClass == "goodField" ? '' : 'greyedOutField'

    const signupPageArray = [
            <>
            <input type="text" value={email} onChange={updateEmail} className={`defaultInput email loginInput ${emailClass}`} placeholder="EMAIL" />
            <input type="password" value={password} onChange={updatePassword} className={`defaultInput password loginInput ${passwordClass}`} placeholder={t('PASSWORD')} />
            <input type="password" value={confirmPassword} onChange={updateConfirmPassword} className={`defaultInput password loginInput ${passwordConfirmClass}`} placeholder={t('CONFIRM-PASSWORD')} />
            <button className={`defaultButton button signUpBtn ${stupidConditional}`} onClick={stupidConditional == '' ? signupNextPage : null}>{t('NEXT')}</button>
            </>,
            <>
            <input type="text" value={username} onChange={updateUsername} className={`defaultInput email loginInput${usernameClass}`} placeholder={t('USERNAME')} />
            <button className="defaultButton button signUpBtn" onClick={signUp} >Create Account</button>
            <button className="defaultButton button signUpbtn" onClick={signupLastPage}>Back</button>
            </>       
        ]

    function signupNextPage() {
        if(signupPage === 0) {
            if(!email || !password || !confirmPassword) {
                //show error message
            }
        }
        setSignupPage(signupPage+1)
    }

    function signupLastPage() {
        setSignupPage(signupPage-1)
    }


    return (
        <div>
            <button className="openLoginModal defaultButton2 btn--primary" onClick={openModal}>LOGIN</button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className="Modal defaultContainer"
                overlayClassName={"modalOverlay"}
                contentLabel="Login"
                closeTimeoutMS={400}
            >


            <div className="WhiteContainer">
                <div className="svg_logo3">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151.58 124.06">
                        <g id="Layer_2" data-name="Layer 2"><polygon className="cls-3" points="0.5 20.52 20.52 0.5 0.5 0.5 0.5 20.52"/></g>
                        <g id="Layer_3" data-name="Layer 3"><polygon className="cls-3" points="20.52 20.52 0.5 40.61 0.71 122.19 114.39 1 85.03 1 20.52 69.25 20.52 20.52"/></g>
                        <g id="Layer_4" data-name="Layer 4"><polygon className="cls-3" points="81.97 55.13 63.54 73.56 113.54 123.56 150.37 123.56 81.97 55.13"/></g>
                    </svg>
                </div>
                {/*
                <div className="svg-logo-full2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576.04 143.34">
                    <g id="Layer_2" data-name="Layer 2"><g id="Layer_7" data-name="Layer 7">
                        <polygon className="full" points="213.79 64.99 202.46 76.36 202.58 122.54 266.93 53.95 250.31 53.95 213.79 92.57 213.79 64.99"/>
                        <polygon className="full" points="248.57 84.58 238.14 95.01 266.45 123.32 287.3 123.32 248.57 84.58"/>
                        <polygon className="full" points="202.46 64.99 213.79 53.66 202.46 53.66 202.46 64.99"/>
                        <text className="full-2" transform="translate(295.8 123.32)">ARU</text><text className="full-3" transform="translate(164.59 123.56)">I</text></g>
                    </g>
                </svg>
                </div> */}
                <div className="welcome">{t('WELCOME')}</div>
                <div className="welcome2">{t('NO-ACCOUNT')}</div>
            </div>    
            <div className="modalLabelContainer">
            <h1 className={`label loginLabel ${mode ? "labelHighlighted" : ""}`} onClick={setLogin}>{t('LOGIN')}</h1>
            <h1 className={`label signUpLabel ${mode ? "" : "labelHighlighted"}`} onClick={setSignup}>{t('SIGNUP')}</h1>
            </div>
            <br></br>
            {mode ? 
            <>
            <input type="text" value={email} onChange={updateEmail} className="defaultInput email loginInput" placeholder="EMAIL" />
            <input type="password" value={password} onChange={updatePassword} className="defaultInput password loginInput" placeholder={t('PASSWORD')} />
            <button className="defaultButtonOnlyText button forgotPasswordBtn">{t('FORGOT-PASS')}</button>
            <button className="defaultButton button loginBtn" onClick={signIn}>{t('SIGNUP')}</button> 
            </>
            : 
            <>
                {signupPageArray[signupPage]}
            </>

            }  
            </Modal>
        </div>
    );
};

export default Login;