import React, { useEffect } from 'react'
import "./about-card.css";
import LangSelector from './LanguageSelector';
import Aos from 'aos';
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
        <div className="about-cards" id="about">
            <div className="card-1" data-aos="fade-down" data-aos-delay="0" >
                <div data-aos-mirror="true" data-aos="fade-down" className="icon-1" data-aos-delay="0"></div>
                <div data-aos-mirror="true" data-aos="fade-down" className="text-1">
                        To Get Started, make an account! <br/>
                        A New Portfolio will be created just for you.
                    </div>
            </div>
            <div className="card-2" data-aos="fade-down" data-aos-delay="1000"  >
                <div data-aos="fade-down" className="text-1" data-aos-delay="1000">Once you make an account, Import your works, <br/>
                    and we’ll start organizing them to your liking.
                    </div>
            </div>
			<div className="card-3"  data-aos-mirror="true" data-aos="fade-down" data-aos-delay="2000" >
            <div data-aos="fade-down" className="text-2" data-aos-delay="2000">Got Services? Great.<br/>
					Create a Services Section <br/>
					For your audience to view.
                    </div>
            </div>
        </div>
  );
};

export default About;