/* eslint-disable no-restricted-globals */
import "./App.css";
import React from 'react'
import { BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import { useState, useEffect } from "react";
import firebaseApp from "./components/utils/firebase"
import { getAuth, onAuthStateChanged } from "firebase/auth";
// Components
import Footer from "./components/footer";
import Header from "./components/header";
//lang
import { useTranslation } from 'react-i18next';

// Pages 
import Home from "./pages/home"
import About from "./pages/About"
import Contact from "./pages/contact"
import Services from "./pages/services";
import profile from "./pages/profile";
import LangSelector from "./components/LanguageSelector";
import Login from "./components/Login"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//check if user is already signed in 
const auth = getAuth(firebaseApp);
onAuthStateChanged(auth, (user) => {
  if (user) {
    const uid = user.uid;
    console.log("user is signed in")
  } else {
    console.log("user is signed out")
  }
});

export default function App() {
  const { t } = useTranslation();

  const [button, setButton] = useState(true)

  const showButton = () => {
      if(window.innerWidth <= 960) {
          setButton(false);
      } else {
          setButton(true);
      }
  };

  useEffect(() => {
      showButton();
  }, []);




window.addEventListener('resize', showButton);

  return (
    <Router>
        <ToastContainer />
      <div id="outer-container">
        <main id="page-wrap" >
          <div className="loading" id="loading"/>
          <div className="backdrop"></div>
          {/*
          <div className="svg-logo-full">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576.04 143.34">
            <g id="Layer_2" data-name="Layer 2"><g id="Layer_7" data-name="Layer 7">
              <polygon className="full" points="213.79 64.99 202.46 76.36 202.58 122.54 266.93 53.95 250.31 53.95 213.79 92.57 213.79 64.99"/>
              <polygon className="full" points="248.57 84.58 238.14 95.01 266.45 123.32 287.3 123.32 248.57 84.58"/>
              <polygon className="full" points="202.46 64.99 213.79 53.66 202.46 53.66 202.46 64.99"/>
              <text className="full-2" transform="translate(295.8 123.32)">ARU</text><text className="full-3" transform="translate(164.59 123.56)">I</text></g>
              </g></svg>
          </div>
          */}
          <div className="" id="yellow_bar"/>
          <div className="solid-lines" id="solid-lines"/>
        <main>
          <Header></Header>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/login" component={Login} />
            <Route path="/contact" component={Contact} />
            <Route path="/services" component={Services} />
            <Route path="/user" component={profile} />
            <Route path="/u" component={profile} />
            <Route path="/profile" component={profile}/>
            <Route path="/profiles" component={profile}/>
          </Switch> 
        </main>
        </main>
      </div>
      <Footer />
    </Router>
  );
}