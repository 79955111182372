import LangSelector from './LanguageSelector';
import Aos from 'aos';
import "aos/dist/aos.css";
import { React, useState, useEffect, } from "react";
import { useTranslation } from 'react-i18next';  
import { useLocation } from 'react-router-dom';
import firebaseApp from "../components/utils/firebase"
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth'
import { DocumentSnapshot, collection, doc, getDocs, getDoc,  getFirestore, connectFirestoreEmulator, query, where} from 'firebase/firestore'
import { useHistory } from "react-router";
import './header.css'



const Header = () => {


  
  const auth = getAuth(firebaseApp)

  const db = getFirestore(firebaseApp);

  const [showHeader, setShowHeader] = useState(false)

  const location = useLocation()


  
  // onAuthStateChanged(auth, (user) => {
  //   if (user) {


  //   } else {



  //   }
  // });

  const history = useHistory()




  function signOutOfApp() {
    signOut(auth)
    history.push("/")
  }

  

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  if(location.pathname.split('/')[1] == '') {
    return null
  } else { 
    return (
      <div id="header" className="header">
          <div className="header-container">
          <div className="svg-logo-home">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151.58 124.06">
                <g id="Layer_2" data-name="Layer 2"><polygon className="cls" points="0.5 20.52 20.52 0.5 0.5 0.5 0.5 20.52"/></g>
                <g id="Layer_3" data-name="Layer 3"><polygon className="cls" points="20.52 20.52 0.5 40.61 0.71 122.19 114.39 1 85.03 1 20.52 69.25 20.52 20.52"/></g>
                <g id="Layer_4" data-name="Layer 4"><polygon className="cls" points="81.97 55.13 63.54 73.56 113.54 123.56 150.37 123.56 81.97 55.13"/></g>
                </svg></div>
          <button onClick={signOutOfApp} className="defaultButton signOutBtn">Signout</button>
          </div>
          <div className="header-color-bottom"></div>
      </div>
      
    );
  };


  }



export default Header;