import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

// const firebaseConfig = {
// 	apiKey: "AIzaSyAdjugbIX8ATl0AHzhvSk7MWAY3kpNzp54",
// 	authDomain: "akeshiro-b8ec5.firebaseapp.com",
// 	projectId: "akeshiro-b8ec5",
// 	storageBucket: "akeshiro-b8ec5.appspot.com",
// 	messagingSenderId: "493800904446",
// 	appId: "1:493800904446:web:8563edc0731c5c4802b7af",
// 	measurementId: "${config.measurementId}"
//   };

//west.kikaru.net
const firebaseConfig = {
	apiKey: "AIzaSyAiPahaSmpVNXWPHZ7J44ylx9SLaBKcVG8",
	authDomain: "kikaru-1dac6.firebaseapp.com",
	projectId: "kikaru-1dac6",
	storageBucket: "kikaru-1dac6.appspot.com",
	messagingSenderId: "1047776706431",
	appId: "1:1047776706431:web:2dc59696a6c8a255e0644a",
	measurementId: "G-EZSGD3JSCX"
  };
  

  //initialize firebase
  const firebaseApp = initializeApp(firebaseConfig);

export default firebaseApp;