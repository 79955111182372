import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';  
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import './usercard.css'
import Aos from 'aos';
import "aos/dist/aos.css";

const UserCard = () => {
	const { t } = useTranslation()

	const history = useHistory()

	function simpleRedirect(event) {
		history.push("/"+event)
	}

	useEffect(() => {
		Aos.init({ duration: 2000 });
	  }, []);

  return (
    <div data-aos="fade-right" className="UserCard">
        <div data-aos="fade-down" data-aos-delay="0" id="Card">
		<div id="Card-h" class="Card">
			<svg class="Rectangle_1">
				<rect id="Rectangle_1" rx="37" ry="37" x="0" y="0" width="416" height="518">
				</rect>
			</svg>
			<img id="banner" src="https://cdn.discordapp.com/attachments/881332662013407333/926677441857093672/pooari-banner.png" srcset="https://pbs.twimg.com/profile_banners/1282144161269870593/1634745552/1500x500" />
				
			<svg class="Path_1" viewBox="752.5 315.134 415.137 449.688">
				<path id="Path_1" d="M 752.5 540 L 1167.63671875 315.1338195800781 L 1167.450317382813 764.8220825195313 L 806.4673461914063 575.5482788085938 L 752.5 540 Z">
				</path>
			</svg>
			<svg class="Rectangle_2">
				<rect id="Rectangle_2" rx="20" ry="20" x="0" y="0" width="415" height="67">
				</rect>
                <rect id="Rectangle_2" rx="0" ry="0" x="0" y="0" width="415" height="50">
				</rect>
			</svg>
			<svg class="Line_1" viewBox="0 0 416 226">
				<path id="Line_1" d="M 416 0 L 0 226">
				</path>
			</svg>
			<img id="pfp" src="https://cdn.discordapp.com/attachments/881332662013407333/926677442075164733/temp_pfp.png" srcset="https://pbs.twimg.com/profile_images/1310080552561590276/za2tIxup_400x400.jpg" />
				
			<div id="username">
				<span>Rive_</span>
			</div>
			<div id="bio">
				<span>しぐれういがイラストを載せたり日記を書いたりするです🌸 イラストは既存絵や新規絵のラフや</span>
			</div>
			<div id="Artist" class="Artist">
				<a className="viewBtn" href="https://www.kikaru.net/u/hilu_glfn0118">{t('VISIT-PROFILE')}</a>
			</div>
		</div>
		</div>
		<div data-aos="fade-up" data-aos-delay="1000" id="Card2">
		<div id="Card-h" class="Card">
			<svg class="Rectangle_1">
				<rect id="Rectangle_1" rx="37" ry="37" x="0" y="0" width="416" height="518">
				</rect>
			</svg>
			<img id="banner" src="https://pbs.twimg.com/profile_banners/1291827298392449024/1642765310/1500x500" />
				
			<svg class="Path_1" viewBox="752.5 315.134 415.137 449.688">
				<path id="Path_1" d="M 752.5 540 L 1167.63671875 315.1338195800781 L 1167.450317382813 764.8220825195313 L 806.4673461914063 575.5482788085938 L 752.5 540 Z">
				</path>
			</svg>
			<svg class="Rectangle_4">
				<rect id="Rectangle_4" rx="20" ry="20" x="0" y="0" width="415" height="67">
				</rect>
                <rect id="Rectangle_4" rx="0" ry="0" x="0" y="0" width="415" height="50">
				</rect>
			</svg>
			<svg class="Line_1" viewBox="0 0 416 226">
				<path id="Line_1" d="M 416 0 L 0 226">
				</path>
			</svg>
			<img id="pfp" src="https://cdn.discordapp.com/attachments/881332662013407333/926677442075164733/temp_pfp.png" srcset="https://pbs.twimg.com/profile_images/1437612407568814080/uO5lOpO9_400x400.jpg" />
				
			<div id="username">
				<span>Riko</span>
			</div>
			<div id="bio">
				<span>commission X</span>
			</div>
			<div id="Artist" class="Artist">
				<a className="viewBtn" href="https://www.kikaru.net/u/rik0ring">{t('VISIT-PROFILE')}</a>
			</div>
		</div>
		</div>
		<div data-aos="fade-up" data-aos-delay="2000" id="Card3">
		<div id="Card-h" class="Card">
			<svg class="Rectangle_1">
				<rect id="Rectangle_1" rx="37" ry="37" x="0" y="0" width="416" height="518">
				</rect>
			</svg>
			<img id="banner" src="https://cdn.discordapp.com/attachments/881332662013407333/926677441857093672/pooari-banner.png" srcset="https://pbs.twimg.com/profile_banners/1480899500055138305/1642008899/1500x500" />
				
			<svg class="Path_1" viewBox="752.5 315.134 415.137 449.688">
				<path id="Path_1" d="M 752.5 540 L 1167.63671875 315.1338195800781 L 1167.450317382813 764.8220825195313 L 806.4673461914063 575.5482788085938 L 752.5 540 Z">
				</path>
			</svg>
			<svg class="Rectangle_5">
				<rect id="Rectangle_5" rx="20" ry="20" x="0" y="0" width="415" height="67">
				</rect>
                <rect id="Rectangle_5" rx="0" ry="0" x="0" y="0" width="415" height="50">
				</rect>
			</svg>
			<svg class="Line_1" viewBox="0 0 416 226">
				<path id="Line_1" d="M 416 0 L 0 226">
				</path>
			</svg>
			<img id="pfp" src="dino" srcset="https://pbs.twimg.com/profile_images/1480905916841185283/lrMspM93_400x400.jpg" />
				
			<div id="username">
				<span>Dino</span>
			</div>
			<div id="bio">
				<span>Drawing Artist palette <br/>tag: #dinaillust <br/> email:  hzk0019@gmail.com</span>
			</div>
			<div id="Artist" class="Artist">
				<a className="viewBtn" href="https://www.kikaru.net/profiles/Dino_Illus">{t('VISIT-PROFILE')}</a>
			</div>
		</div>
		</div>
	</div>
  );
};
  
export default UserCard;