import React from "react";
import { useTranslation } from 'react-i18next';  
import './style.css'
import About from '../components/about-card';

const services = () => {
  return (
    <div className="">
      <About></About>
    </div>
  );
};
  
export default services;