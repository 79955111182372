import { React, useState, useEffect} from "react";
import { useTranslation } from 'react-i18next';  
import firebaseApp from "../components/utils/firebase"
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth'
import { DocumentSnapshot, collection, doc, getDocs, getDoc,  getFirestore, connectFirestoreEmulator, query, where} from 'firebase/firestore'
import { useHistory } from "react-router";
import './profile.css'
import Header from '../components/header';

const Profile = () => {

  const auth = getAuth(firebaseApp)

  const db = getFirestore(firebaseApp);

  const [profileData, setProfileData] = useState(Object)

  const [profileName, setProfileName] = useState(window.location.pathname.split("/")[2]);

  const [sectionIndex, setSectionIndex] = useState(1)

  const history = useHistory()

  //temp
  // const tempWhitelist = ['7ut9XuVWNtXM1fGeDBwpas4ieex1', 'WE6YtuJmwdhepiFetglfV2um0ix1', '2ipaZiSfLzbuPeFZBvgxwPKGKjI3', 'OtS5Oii0P0MaelcbaXnd4F1USgh1','vH94L4fUJOX9BH5DNvNKwccdl353','MOq9ZZokZxZfTTkm4dNloMgsKED2','ayeoovdA5IfGt0T4aWEyDvfO2pB2']

  onAuthStateChanged(auth, (user) => {
    // if (user) {
    //   if(profileUID == '' || !profileUID) {
    //     // if(!tempWhitelist.includes(user.uid)) {history.push("/")}
    //     setProfileUID(user.uid)

    //   } 

    // } else {


    // }
  });

  async function getUserProfile(uid) {
    try {

      if(uid==undefined) {
        console.log("UID is undefined, pulling latest auth value")
        uid = auth.currentUser.uid
      }

      console.log("UID: "+uid)

    const docRef = doc(db, "users", `${uid}`);

    let docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      setProfileData(docSnap.data())
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
    } catch (error) {
      console.error(error);
    }
  }

  async function getUserProfileFromUsername(username) {
    console.log("Username: "+username)

    const usersRef = collection(db, "users");
    const q = query(usersRef, where("username", "==", `${username}`))

    const querySnapshot = await getDocs(q)
    

    if(querySnapshot.empty) {
      history.push("/")
      return
    }

    console.log(querySnapshot.docs[0].data())
    setProfileData(querySnapshot.docs[0].data())
    
  }

  const [lowerBoxLoading, setLowerBoxLoading] = useState(true)

  useEffect(() => {

    setTimeout(() => {
     
      if(profileName) {
        if(profileName.length <=24 && profileName.length >=3) {
          console.log('Retrieving profile from name')
          getUserProfileFromUsername(profileName)
        } else {
          if(profileName.length == 28) {
            console.log('Retrieving profile name from given UID')
            getUserProfile(profileName)
          }
        }
      } else {
        console.log("Retrieving profile from currently signed in account")
        getUserProfile()
      }

    }, 500);
    
    console.log(lowerBoxLoading)
    setTimeout(() => {
        setLowerBoxLoading(false)
    }, 2500);
    
  }, [profileName]);


  function signOutOfApp() {
    signOut(auth)
  }

  let pfpimage = profileData.pfplink ? profileData.pfplink : null;

  let bannerimage = profileData.bannerlink ? profileData.bannerlink : null;

  function setSectionIndexProxy(event) {
    setSectionIndex(parseInt(event.target.value))
  }

  const ServicesCard = (svctext1, svctext2) => {
  return <div className="servicesCard"><p className="servicesCardText1">{svctext1}</p><p className="servicesCardText2">{svctext2}</p></div>
}

  const [mediaList, setMediaList] = useState(Array)


  return (
    <div id="profile" className="profile">
      <div className="background"></div>
      <div className="profileHeaderBox" style={{backgroundImage: `url(${profileData.bannerlink})` }}>
      </div>
      <div id="user">
        <div className="name">{profileData.name}</div>
        <p>@{profileData.username}</p>
        <div className="bio">{profileData.bio}</div>
        <div className="pfp" style={{backgroundImage: `url(${profileData.pfplink})` }} />
        <div className="followBtn"><div className="text">FOLLOW</div></div>
        <a className="twitter" id="twitter" target="_blank" href={profileData.twitter}></a>
      
      </div>
      <div className="sectionContainer" wait="1000">
        <button className="mediaPageBtn" value="0" onClick={setSectionIndexProxy}>Media</button>
        <button className="servicesPageBtn" value="1" onClick={setSectionIndexProxy}>Services</button>
        {lowerBoxLoading ? <></> :
          <div className="sectionContent" >
          { 
            sectionIndex === 0 && <div className="profileMedia">{}</div>
            // sectionIndex === 1 && <div className="profileServices">{profileData.services && Array(profileData.services).map((_, i) => <ServicesCard key={i} />)}</div>
          }
          </div>
        }
      </div>
      <div className="contentPage"></div>
    </div>
  );
};
  
export default Profile;