import React from "react";
import { useTranslation } from 'react-i18next';  

const About = () => {
  return (
    <div className="contact">
      <h1>Hi</h1>
    </div>
  );
};
  
export default About;