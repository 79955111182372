/* eslint-disable no-restricted-globals */
import { default as iziToast, IziToastSettings } from 'izitoast';
import React, { Component, useEffect, useState, useRef} from 'react';
import { useTranslation } from 'react-i18next';
import "./home.css";
import '../defaults.css'
import Login from '../components/Login'
import Popup from 'reactjs-popup';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import firebaseApp from '../components/utils/firebase';
import LangSelector from '../components/LanguageSelector';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router';
import UserCard from '../components/UserCard';
import Aos from 'aos';
import "aos/dist/aos.css";
import About from "../components/about-card";


Aos.init();

// You can also pass an optional settings object
// below listed default settings
Aos.init({
  // Global settings:
  debounceDelay: 50, // the delay on debounce used while resizing window (advanced)


  // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
  once: false, // whether animation should happen only once - while scrolling down
  mirror: false, // whether elements should animate out while scrolling past them

});



const queryString = require('query-string')


export const Home = () => {

	const { t } = useTranslation();

	const [button, setButton] = useState(true)

	const [loggedIn, setLoggedIn] = useState(false)

	const history = useHistory()

	const showButton = () => {
		if(window.innerWidth <= 10) {
			setButton(false);
		} else {
			setButton(true);
		}
	};

	const auth = getAuth(firebaseApp);
	onAuthStateChanged(auth, (user) => {
	if (user) {
		const uid = user.uid;
		setLoggedIn(true)
		console.log("user is signed in")
	} else {
		setLoggedIn(false)
		console.log("user is signed out")
	}
	});

	function simpleRedirect(event) {
		history.push("/"+event.target.value)
	}

	const loginRef = useRef(null)

	function clickLogin() {
		loginRef.current.click()
	}

	useEffect(() => {
		showButton();
	}, []);

	useEffect(() => {
		Aos.init({ duration: 2000 });
	  }, []);

	return  (
	<div>
		<div className="slide-in"><div className="text-in"></div>
        </div>
		<div className="svg-logo">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151.58 124.06">
              <g id="Layer_2" data-name="Layer 2"><polygon className="cls-1" points="0.5 20.52 20.52 0.5 0.5 0.5 0.5 20.52"/></g>
              <g id="Layer_3" data-name="Layer 3"><polygon class="cls-1" points="20.52 20.52 0.5 40.61 0.71 122.19 114.39 1 85.03 1 20.52 69.25 20.52 20.52"/></g>
              <g id="Layer_4" data-name="Layer 4"><polygon class="cls-1" points="81.97 55.13 63.54 73.56 113.54 123.56 150.37 123.56 81.97 55.13"/></g>
              </svg></div>
        <div className="kikaru-blue"></div>
        <div className="cover-in"><div className="text-in"></div>
        </div>
		<div className="animated-image" id="animated-image"/>
        <div className="gray_bar" id="gray_bar"/>
		<div className="movie" id="movie"></div>
		<div className="about-section1">
			<div className="about-header">{t('ORGANIZE')}</div>
			<div className="about-header2">{t('SERVICES')}</div>
			<div className="about-text">{t('ORGANIZE-TEXT')}</div>
			<div className="about-text2">{t('SERVICES-TEXT')}</div>
			</div>
		<div className="kikaru-white"></div>
		<div className="movie-overlay" id="movie">
		<div className="movie-overlay-left" id="movie"></div>
		</div>
		<div className="frontContainer" id="frontContainer">
			<div className="top-LangSelector"><LangSelector></LangSelector></div>
			<div ref={loginRef}>
				{loggedIn ? <button id="loggedInButton" className="defaultButton homebtn" value="profile" onClick={simpleRedirect}>Profile</button> : <Login id="loginModalButton"/>}
			</div>
			<div className="rev-block" id="home_info">{t('HOME_TEXT_1')}<br/></div>
			<div className="kikaru">{t('HOME_TEXT_2')}</div>
			<div className="description">{t('HOME_TEXT_4')}</div>
			<div id="homeButtonContainer" >
				<a className="defaultButton front-button btn--primary" href="#about">{t('LEARN-MORE')}</a>
				<button className="defaultButton defaultButtonHollowed front-button" href="#featured" onClick="" >{t('SERVICES')}</button>
				</div>
				<p id="btnPagetop" className="btn-down"><a href="#featured" data-offset="0" class="ancor"><span class="btn--pagetop__icon">
        		<svg xmlns="http://www.w3.org/2000/svg" width="70" height="72" viewBox="0 0 70 72">
					<path fill="#ffffff" d="M-5094.336-1059.688a1.063,1.063,0,0,1,0-1.522l32.674-32.083h-67.269a1.179,1.179,0,0,1-1.18-1.179,1.179,1.179,0,0,1,1.18-1.178h67.06l-32.465-31.88a1.067,1.067,0,0,1,0-1.527,1.113,1.113,0,0,1,1.555,0l33.956,33.343a1.08,1.08,0,0,1,.2.268,1.176,1.176,0,0,1,.515.974,1.178,1.178,0,0,1-.419.9,1.06,1.06,0,0,1-.3.533l-33.962,33.35a1.1,1.1,0,0,1-.774.315A1.1,1.1,0,0,1-5094.336-1059.688Z" transform="translate(1129.373 -5058.11) rotate(-90)">
						</path>
							</svg>
								</span>
									<span className="btn-down_text"></span></a></p>
			<p  data-aos="fade-up" id="btnPagetop" className="btn-show"><a href="#" data-offset="0" class="ancor"><span class="btn--pagetop__icon">
        		<svg xmlns="http://www.w3.org/2000/svg" width="70" height="72" viewBox="0 0 70 72">
					<path fill="#ffffff" d="M-5094.336-1059.688a1.063,1.063,0,0,1,0-1.522l32.674-32.083h-67.269a1.179,1.179,0,0,1-1.18-1.179,1.179,1.179,0,0,1,1.18-1.178h67.06l-32.465-31.88a1.067,1.067,0,0,1,0-1.527,1.113,1.113,0,0,1,1.555,0l33.956,33.343a1.08,1.08,0,0,1,.2.268,1.176,1.176,0,0,1,.515.974,1.178,1.178,0,0,1-.419.9,1.06,1.06,0,0,1-.3.533l-33.962,33.35a1.1,1.1,0,0,1-.774.315A1.1,1.1,0,0,1-5094.336-1059.688Z" transform="translate(1129.373 -5058.11) rotate(-90)">
						</path>
							</svg>
								</span>
									<span className="btn-show_text"></span></a></p>
			<div className="wrapper"></div>
			<UserCard></UserCard>
			<div data-aos="fade-down" className="featured-text" id="featured">{t('FEATURED')}</div>
			<div data-aos="fade-down" className="featured-line"></div>
			<div className="wrapper-box">
				<div data-aos="fade-down" className="text-4" id='about'>{t('GOAL')}</div>
				<div data-aos="fade-down" className="text-5" data-aos-delay="20">{t('GOAL-TEXT')}</div>
			</div>
			<div className="wrapper-angled2"></div>
			
			
			<div className="animation_1" id="animation_1"></div>
			<div id="headerNav" className="header__nav"></div>
				<div id="headerNavCover" className="header__nav__cover"></div>
		</div>
		<div className="footerspace"></div>
	</div>
	);
  }



export default Home