import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './lang.css';
import Aos from 'aos';
import "aos/dist/aos.css";

const LangSelector = () => {
  const { i18n } = useTranslation();
 

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const changeLanguage = (lng) => {
	console.log('changing language')
    i18n.changeLanguage(lng);
  }
 
  return (
    <div data-aos="fade-up" className="lang-container">
      
      <div className={"lang_switch"}>
      <button className="text" onClick={() => changeLanguage('en')}>EN</button> 
      <button className="text">|</button>
      <button  className="text" onClick={() => changeLanguage('kr')}>한국어</button>
      </div>
    </div>
  )
}
 
export default LangSelector;